<template>
  <vue2Dropzone
    id="dropzone"
    ref="drop-zone"
    :options="dropzoneOptions"
    @vdropzone-removed-file="onRemovedFile"
  />
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'

const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png']

export default {
  name: 'FileDropZone',
  components: {
    vue2Dropzone,
  },
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    maxFiles: {
      type: Number,
      default: null,
    },
    acceptedExtensions: {
      type: String,
      default: 'image/*,application/pdf,text/csv,application/msword,application/vnd.ms-excel,'
          + 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
          + 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,'
          + 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    },
    paramName: {
      type: String,
      default: 'file',
    },
  },
  computed: {
    dropzoneOptions() {
      return {
        url: 'null',
        maxFilesize: 6,
        maxFiles: this.maxFiles,
        acceptedFiles: this.acceptedExtensions,
        dictDefaultMessage: 'Arrastra aquí los archivos',
        dictFileTooBig: 'Archivo demasiado grande',
        wasQueueAutoProcess: false,
        autoQueue: false,
        addRemoveLinks: true,
        dictRemoveFile: 'Eliminar',
        thumbnailWidth: 175,
        thumbnailHeight: 175,
      }
    },
    dropZone() {
      return this.$refs['drop-zone']
    },
  },
  watch: {
    files: {
      handler() {
        this.populateDropZone()
      },
      deep: true,
    },
  },
  methods: {
    populateDropZone() {
      if (!this.files || this.files.length === 0) {
        return
      }

      this.files.forEach(file => {
        const parts = file.path ? file.path.split('.') : file.name.split('.')
        const extension = parts[parts.length - 1] ? parts[parts.length - 1].toLocaleLowerCase() : null
        this.dropZone.manuallyAddFile(file, file.path || file.name)
        if (IMAGE_EXTENSIONS.includes(extension)) {
          const path = `${process.env.VUE_APP_ROOT_API}/image/${file.id}`
          this.dropZone.dropzone.emit('thumbnail', file, path)
        }

        this.dropZone.dropzone.emit('complete', file)
      })
    },
    onRemovedFile(file) {
      if (!file.manuallyAdded) {
        return
      }

      this.$emit('removed-file', file)
    },
    getFiles() {
      return this.dropZone.getAcceptedFiles()
    },
    removeAllFiles() {
      this.dropZone.removeAllFiles()
    }
  },
}
</script>

<style scoped>

</style>
